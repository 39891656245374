var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crosshairs-icon" }, [
    _c(
      "svg",
      {
        class: { loading: _vm.loading },
        attrs: {
          "xmlns:dc": "http://purl.org/dc/elements/1.1/",
          "xmlns:cc": "http://creativecommons.org/ns#",
          "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
          "xmlns:svg": "http://www.w3.org/2000/svg",
          xmlns: "http://www.w3.org/2000/svg",
          version: "1.1",
          viewBox: "0 0 24 24",
        },
      },
      [
        _c("path", {
          staticStyle: { fill: "#000000" },
          attrs: {
            fill: "currentColor",
            d: "M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z",
          },
        }),
        _c("circle", {
          staticClass: "center",
          staticStyle: { fill: "#000000" },
          attrs: { r: "4.6103544", cy: "11.999999", cx: "12" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }