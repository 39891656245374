var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "era-arrow-icon" }, [
    _c("svg", { attrs: { viewBox: "0 0 300 300" } }, [
      _c("path", {
        attrs: {
          d: "M192.67,45.43c0,52.65,42.82,95.48,95.45,95.48v18.19c-52.64,0-95.45,42.83-95.45,95.48h-18.18c0-39.98,20.76-75.2,52.06-95.48H11.88v-18.19H226.55c-31.3-20.28-52.06-55.5-52.06-95.48h18.18Z",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }