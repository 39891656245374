import { render, staticRenderFns } from "./QsLogoIcon.vue?vue&type=template&id=040e11eb&scoped=true&"
import script from "./QsLogoIcon.vue?vue&type=script&lang=js&"
export * from "./QsLogoIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040e11eb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_mouvement_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('040e11eb')) {
      api.createRecord('040e11eb', component.options)
    } else {
      api.reload('040e11eb', component.options)
    }
    module.hot.accept("./QsLogoIcon.vue?vue&type=template&id=040e11eb&scoped=true&", function () {
      api.rerender('040e11eb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Icons/QsLogoIcon.vue"
export default component.exports