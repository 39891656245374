var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "app-notification alert alert-" +
        _vm.type +
        " shadow-lg mb-3 opacity-" +
        (_vm.active ? "1" : "0"),
    },
    [
      _c("div", [
        (_vm.icon || _vm.type) === "error"
          ? _c(
              "svg",
              {
                staticClass: "stroke-current flex-shrink-0 h-6 w-6",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  fill: "none",
                  viewBox: "0 0 24 24",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z",
                  },
                }),
              ]
            )
          : (_vm.icon || _vm.type) === "success"
          ? _c(
              "svg",
              {
                staticClass: "stroke-current flex-shrink-0 h-6 w-6",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  fill: "none",
                  viewBox: "0 0 24 24",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z",
                  },
                }),
              ]
            )
          : (_vm.icon || _vm.type) === "warning"
          ? _c(
              "svg",
              {
                staticClass: "stroke-current flex-shrink-0 h-6 w-6",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  fill: "none",
                  viewBox: "0 0 24 24",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z",
                  },
                }),
              ]
            )
          : _vm._e(),
        _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }