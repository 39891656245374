var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menu-icon" }, [
    _c(
      "svg",
      {
        staticStyle: { width: "24px", height: "24px" },
        attrs: { viewBox: "0 0 24 24" },
      },
      [
        _c("path", {
          attrs: {
            fill: "currentColor",
            d: "M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }