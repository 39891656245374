var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c("div", { staticClass: "qs-base-modal" }, [
        _c("input", {
          staticClass: "modal-toggle",
          attrs: { type: "checkbox", checked: "checked", "aria-hidden": "" },
          domProps: { value: _vm.value },
        }),
        _c(
          "div",
          {
            staticClass: "modal",
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "Escape",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                return _vm.closeModal.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "modal-box" }, [
              !_vm.persistent
                ? _c(
                    "button",
                    {
                      staticClass:
                        "ursor-pointer absolute right-4 top-4 z-10 md:block hidden",
                      attrs: { id: "close-button" },
                      on: {
                        click: _vm.closeModal,
                        keypress: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "Enter",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            return _vm.closeModal.apply(null, arguments)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "Space",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            return _vm.closeModal.apply(null, arguments)
                          },
                        ],
                      },
                    },
                    [_c("qs-icon", { attrs: { icon: "close" } })],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "qs-base-modal__content" },
                [_vm._t("default")],
                2
              ),
              _c(
                "div",
                { staticClass: "qs-base-modal__actions" },
                [
                  _vm._t("actions", null, null, { closeModal: _vm.closeModal }),
                  !_vm.persistent
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "button button--drop-shadow button--outline w-full md:hidden",
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v(" Fermer ")]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }