var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["qs-icon", "size-" + _vm.size] },
    [
      _vm.icon === "accessibility"
        ? [_c("accessibility-icon")]
        : _vm.icon === "arrow"
        ? [_c("arrow-icon")]
        : _vm.icon === "account"
        ? [_c("account-icon")]
        : _vm.icon === "alert-circle"
        ? [_c("alert-circle-icon")]
        : _vm.icon === "calendar-clock"
        ? [_c("calendar-clock-icon")]
        : _vm.icon === "calendar"
        ? [_c("calendar-icon")]
        : _vm.icon === "calendar-star"
        ? [_c("calendar-star-icon")]
        : ["check-circle", "check-circle-outline"].includes(_vm.icon)
        ? [
            _c("check-mark-icon", {
              attrs: { outline: _vm.icon === "check-circle-outline" },
            }),
          ]
        : ["close", "close-outline"].includes(_vm.icon)
        ? [
            _c("close-icon", {
              attrs: { outlined: _vm.icon === "close-outline" },
            }),
          ]
        : _vm.icon === "crosshairs"
        ? [_c("crosshairs-icon", { attrs: { loading: _vm.loading } })]
        : _vm.icon === "currency-usd"
        ? [_c("currency-usd-icon")]
        : _vm.icon === "era-arrow"
        ? [_c("era-arrow-icon")]
        : _vm.icon === "heart"
        ? [_c("heart-icon")]
        : _vm.icon === "information"
        ? [_c("information-icon")]
        : _vm.icon === "map-marker"
        ? [_c("map-marker-icon")]
        : _vm.icon === "menu"
        ? [_c("menu-icon")]
        : [_c("qs-logo-icon")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }