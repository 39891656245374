var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-base-modal",
    {
      staticClass: "adhesion-onboarding",
      attrs: { persistent: "", value: _vm.value },
    },
    [
      _c("div", { staticClass: "onboarding-step prose" }, [
        _c("h2", [
          _vm._v(" Bienvenue sur "),
          _c("strong", [_vm._v("Mouvement")]),
          _vm._v(","),
          _c("br"),
          _vm._v("la plateforme de mobilisation de Québec solidaire! "),
        ]),
        _c("p", [
          _vm._v(
            " La plus grande campagne de notre histoire est en marche. Partout au Québec, des solidaires vous attendent. Peu importe votre expérience d’implication, faire élire des solidaires dans votre région n’a jamais été aussi facile. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " Trouvez des équipes solidaires près de chez vous et inscrivez-vous aux activités pour changer le Québec avec nous et contribuer aux victoires solidaires! "
          ),
        ]),
        _c(
          "button",
          {
            staticClass: "button button--primary button--drop-shadow mt-6 mb-1",
            on: {
              click: _vm.complete,
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k(
                      $event.keyCode,
                      "Enter",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  return _vm.complete.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k(
                      $event.keyCode,
                      "Space",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  return _vm.complete.apply(null, arguments)
                },
              ],
            },
          },
          [_vm._v(" Continuer ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }