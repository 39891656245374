var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "qs-mouvement" }, [
    _c("svg", { attrs: { viewBox: "0 0 1093.69 355.86" } }, [
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M142.38,194.36c16.42-3.87,26.62-20.49,22.8-37.12-3.82-16.63-20.23-26.97-36.64-23.1-16.42,3.87-26.62,20.49-22.8,37.12,3.82,16.63,20.23,26.97,36.64,23.1Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M63.45,24.07c.48,.12,.97,.1,1.41-.04,14.11-4.55,29.13-6.04,44.28-4.22,2.95,.35,5.88,.83,8.76,1.42,24.33,5.01,45.91,18.44,61.45,38.42,17.38,22.35,25.13,50.21,21.83,78.46-2.26,19.31-11.8,36.57-26.88,48.6-2.21,1.76-4.51,3.39-6.87,4.87-.56,.34-.98,.89-1.16,1.58-.34,1.38,.49,2.76,1.84,3.11,.38,.1,.77,.1,1.12,.03,22.55-4.78,42.37-17.56,56.28-36.43,31.22-42.34,22.63-102.49-19.16-134.1C188.44,12.18,167.17,3.6,144.86,.91c-28.99-3.47-57.65,3-82.11,18.34-.58,.34-1.02,.92-1.19,1.62-.35,1.41,.5,2.84,1.89,3.19Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M72.09,239.29c-10.94-10.1-19.73-22.54-25.74-36.73-1.18-2.77-2.23-5.57-3.16-8.39-7.88-23.85-7.19-49.49,2.12-73.11,10.41-26.43,30.36-47.16,56.17-58.39,17.63-7.67,37.17-7.93,54.99-.72,2.62,1.06,5.16,2.26,7.61,3.6,.57,.32,1.25,.42,1.93,.23,1.34-.39,2.13-1.81,1.74-3.17-.1-.38-.3-.72-.54-1-15.36-17.39-36.2-28.39-59.29-31.16C56.09,24.22,8.96,61.84,2.82,114.29c-2.65,22.49,.64,45.44,9.5,66.36,11.52,27.17,31.38,49.08,56.73,62.87,.58,.34,1.29,.43,1.99,.24,1.39-.4,2.19-1.86,1.79-3.26-.13-.49-.39-.9-.73-1.22Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M257.64,125.86c0-.68-.28-1.35-.79-1.86-1.04-1.02-2.68-.99-3.68,.06-.35,.36-.57,.8-.67,1.25-3.16,14.65-9.41,28.58-18.53,40.95-1.78,2.42-3.65,4.74-5.6,6.97-16.45,18.83-38.72,31.05-63.56,34.69-27.79,4.08-55.5-3.05-77.99-20.08-15.38-11.63-25.36-28.64-28.11-47.88-.4-2.82-.64-5.66-.72-8.46,0-.65-.27-1.31-.77-1.8-1-.99-2.61-.96-3.58,.06-.27,.28-.47,.62-.58,.98-7.19,22.18-6.17,45.95,3.01,67.59,8.75,20.63,23.85,36.55,42,46.45,1.7,.93,1.86,3.29,.36,4.53-14.44,11.82-21.44,31.31-24.08,48.13-.41,2.64,2.7,4.39,4.65,2.59,10.33-9.61,25.44-16.21,45.13-13.64,31.06,4.04,58.05,20.69,79.9,67.82,1.32,2.84,5.53,1.76,5.32-1.38-2.82-43.06-17.5-76.91-38.39-96.5-1.52-1.43-.98-3.95,1-4.6,2.8-.91,5.59-1.96,8.34-3.16,20.55-8.91,38.53-23.28,51.98-41.52,17.48-23.69,26.27-52.07,25.39-81.2Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M401.27,126.01v103.02h-19.63v-57.24l-26.16,57.24h-13.08l-26.16-57.24v57.24h-19.62V126.01h17.17l35.16,76.04,35.16-76.04h17.17Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M454.42,229.85c-20.44,0-37.61-17.17-37.61-38.43s17.17-38.43,37.61-38.43,37.61,17.17,37.61,38.43-17.17,38.43-37.61,38.43Zm0-17.99c10.63,0,18.81-8.18,18.81-20.44s-8.18-20.44-18.81-20.44-18.8,8.18-18.8,20.44,8.17,20.44,18.8,20.44Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M505.95,197.96v-44.15h18.8v41.7c0,9.81,6.54,16.35,15.53,16.35s15.54-6.54,15.54-16.35v-41.7h18.8v75.22h-13.9l-3.27-7.36s-9,8.18-21.26,8.18c-17.99,0-30.25-12.26-30.25-31.89Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M659.67,153.81l-28.62,75.22h-17.99l-28.62-75.22h19.63l17.99,52,17.99-52h19.63Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M736.53,206.14c-4.09,11.45-16.35,23.71-33.53,23.71-20.44,0-37.61-17.17-37.61-38.43s17.17-38.43,37.61-38.43,35.98,15.54,35.98,38.43c0,3.27-.82,7.36-.82,7.36h-52.33c1.63,7.36,7.36,13.08,17.17,13.08s13.9-5.72,13.9-5.72h19.63Zm-51.51-20.44h35.98c-1.64-8.18-8.18-14.72-17.99-14.72s-16.35,6.54-17.99,14.72Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M809.31,162.8s9-9.81,22.08-9.81c16.35,0,26.98,10.63,26.98,31.07v44.97h-18.81v-43.34c0-8.99-5.72-14.72-12.26-14.72s-12.26,5.72-12.26,14.72v43.34h-18.8v-43.34c0-8.99-5.72-14.72-12.26-14.72s-12.26,5.72-12.26,14.72v43.34h-18.8v-75.22h13.9l3.27,7.36s7.36-8.18,17.99-8.18c15.54,0,21.26,9.81,21.26,9.81Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M942.6,206.14c-4.09,11.45-16.35,23.71-33.53,23.71-20.44,0-37.61-17.17-37.61-38.43s17.17-38.43,37.61-38.43,35.98,15.54,35.98,38.43c0,3.27-.82,7.36-.82,7.36h-52.33c1.63,7.36,7.36,13.08,17.17,13.08s13.9-5.72,13.9-5.72h19.63Zm-51.51-20.44h35.98c-1.64-8.18-8.18-14.72-17.99-14.72s-16.35,6.54-17.99,14.72Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M1027.64,184.88v44.15h-18.81v-41.7c0-9.81-6.54-16.35-15.53-16.35s-15.53,6.54-15.53,16.35v41.7h-18.8v-75.22h13.9l3.27,7.36s9-8.18,21.26-8.18c17.99,0,30.25,12.26,30.25,31.89Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M1052.99,135.82h16.36v17.99h16.35v17.17h-16.35v31.07c0,6.54,4.09,10.63,10.63,10.63,3.27,0,4.91-.82,4.91-.82v16.35s-4.09,1.63-9,1.63c-14.72,0-25.35-10.63-25.35-26.98v-31.89h-11.45v-15.53h4.91c5.72,0,8.99-3.27,8.99-9v-10.63Z",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }