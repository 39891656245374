var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "root",
      attrs: { id: "qs_mouvement_v2", "data-theme": "labase" },
    },
    [
      _c("header", { attrs: { id: "campagne-header" } }),
      _c(
        "div",
        {
          class: [
            "bg-base-100",
            "drop-shadow-md",
            "fixed",
            "flex-col",
            "flex z-5",
            "h-30",
            "items-center",
            "md:drop-shadow-none",
            "top-100",
            "w-full",
          ],
        },
        [
          _c(
            "div",
            {
              staticClass:
                "max-w-5xl w-full root__navbar navbar h-18 z-10 hidden md:flex",
            },
            [
              _c(
                "div",
                { staticClass: "navbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "flex flex-row items-center",
                      attrs: { to: "/carte" },
                    },
                    [
                      _c("qs-mouvement", {
                        staticClass: "h-10 ml-2 md:ml-0 md:h-14",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "navbar-end" }, [
                _c("div", { staticClass: "navbar-center hidden md:flex" }, [
                  _c("ul", { staticClass: "menu menu-horizontal p-0" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "navbar-link",
                            attrs: {
                              to: "/carte",
                              "active-class": "underline underline-offset-4",
                            },
                          },
                          [_vm._v("Carte")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "navbar-link",
                            attrs: { to: "/evenements-virtuels" },
                          },
                          [
                            _c("div", { staticClass: "indicator" }, [
                              _vm._v(" Événements virtuels "),
                              _vm.virtualEventsCount
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "-right-2 indicator-item indicator-end badge badge-accent",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.virtualEventsCount) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "navbar-link",
                            attrs: { to: "/carte/nouveau" },
                          },
                          [_vm._v(" Créer ")]
                        ),
                      ],
                      1
                    ),
                    _vm.user
                      ? _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "navbar-link",
                                attrs: { to: "/mes-evenements" },
                              },
                              [_vm._v(" Mes événements ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "root__view" },
        [
          _c(
            "div",
            {
              staticClass:
                "root__view__alerts absolute right-3 top-3 md:top-14 z-10",
            },
            _vm._l(_vm.notifications, function (n, index) {
              return _c(
                "app-notification",
                _vm._b({ key: index }, "app-notification", n, false)
              )
            }),
            1
          ),
          _c("router-view"),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "bg-base-100 fixed bottom-0 w-full md:hidden flex-col items-center flex",
        },
        [
          _c(
            "div",
            { staticClass: "max-w-5xl w-full root__navbar navbarz-10" },
            [
              _c("div", { staticClass: "flex justify-center w-full" }, [
                _c("ul", { staticClass: "menu menu-horizontal p-0" }, [
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "navbar-link",
                          attrs: {
                            to: "/carte",
                            "active-class": "underline underline-offset-4",
                          },
                        },
                        [_vm._v(" Carte ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "navbar-link",
                          attrs: { to: "/evenements-virtuels" },
                        },
                        [
                          _c("div", { staticClass: "indicator" }, [
                            _vm._v(" Événements virtuels "),
                            _vm.virtualEventsCount
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "-right-2 indicator-item indicator-end badge badge-accent",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.virtualEventsCount) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "navbar-link",
                          attrs: { to: "/carte/nouveau" },
                        },
                        [_vm._v(" Créer ")]
                      ),
                    ],
                    1
                  ),
                  _vm.user
                    ? _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "navbar-link",
                              attrs: { to: "/mes-evenements" },
                            },
                            [_vm._v(" Mes événements ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("adhesion-onboarding", {
        attrs: { value: _vm.showAdhesionOnboardingModal },
        on: { complete: _vm.setAdhesionOnboardingComplete },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }